import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select, Typography } from 'antd';
import { ICertifiers } from 'interfaces/certifiers';
import { ISignaturePartners } from 'interfaces/signature_partners';
import { ISignatures } from 'interfaces/signatures';

export const SignaturePartnersCreate = () => {
  const { form, formProps, saveButtonProps, onFinish } =
    useForm<ISignaturePartners>({
      successNotification: () => ({
        type: 'success',
        description: 'Sucesso!',
        message: 'Modelo de assinatura criado com sucesso!'
      }),
      errorNotification: () => ({
        type: 'error',
        description: 'Error!',
        message: 'Não foi possível criar o modelo de assinatura!'
      })
    });
  const certifierId = Form.useWatch('certifier_id', form);
  const kind = Form.useWatch('report_kind', form);

  const { selectProps: selectCertifiers } = useSelect<ICertifiers>({
    resource: 'certifiers',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 100,
    liveMode: 'manual',
    pagination: {
      mode: 'server'
    }
  });
  const { selectProps: selectSignatures } = useSelect<ISignatures>({
    resource: 'certifiers/signatures',
    filters: [
      {
        field: 'by_certifier_id',
        operator: 'eq',
        value: certifierId
      }
    ],
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 100,
    liveMode: 'manual',
    pagination: {
      mode: 'server'
    }
  });

  const handleFinish = () => {
    onFinish({
      ...form.getFieldsValue(),
      signatures: {
        front_signature_id: form.getFieldValue('front_signature_id'),
        back_signature_id: form.getFieldValue('back_signature_id'),
        record_back_signature_id: form.getFieldValue('record_back_signature_id')
      }
    });
  };

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Criar modelo de assinatura"
    >
      <Form {...formProps} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label="Nome do modelo"
          name="name"
          rules={[
            {
              required: true,
              message: 'Nome do modelo obrigatório'
            }
          ]}
        >
          <Input className="max-w-[500px]" />
        </Form.Item>
        <Form.Item
          label="Nome da instituição"
          name="certifier_id"
          rules={[
            {
              required: true,
              message: 'Nome da instituição obrigatório'
            }
          ]}
        >
          <Select {...selectCertifiers} allowClear style={{ minWidth: 100 }} />
        </Form.Item>
        <Form.Item
          label="Local"
          name="report_kind"
          rules={[
            {
              required: true,
              message: 'Local obrigatório'
            }
          ]}
        >
          <Select allowClear style={{ minWidth: 100 }}>
            <Select.Option
              key={'graduate_enrollment_academic_record'}
              value={'graduate_enrollment_academic_record'}
            >
              Histórico escolar
            </Select.Option>
            <Select.Option
              key={'student_graduate_course_finished'}
              value={'student_graduate_course_finished'}
            >
              Certificado
            </Select.Option>
          </Select>
        </Form.Item>

        <Typography.Title level={5} style={{ margin: '24px 0' }}>
          Assinaturas
        </Typography.Title>
        <Form.Item
          label="Assinatura Frente"
          name="front_signature_id"
          rules={[
            {
              required: true,
              message: 'Nome da instituição obrigatório'
            }
          ]}
        >
          <Select {...selectSignatures} allowClear style={{ minWidth: 100 }} />
        </Form.Item>
        {kind === 'student_graduate_course_finished' && (
          <>
            <Form.Item
              label="Assinatura Verso"
              name="back_signature_id"
              rules={[
                {
                  required: true,
                  message: 'Nome da instituição obrigatório'
                }
              ]}
            >
              <Select
                {...selectSignatures}
                allowClear
                style={{ minWidth: 100 }}
              />
            </Form.Item>
            <Form.Item
              label="Assinatura Verso - Registro"
              name="record_back_signature_id"
              rules={[
                {
                  required: true,
                  message: 'Nome da instituição obrigatório'
                }
              ]}
            >
              <Select
                {...selectSignatures}
                allowClear
                style={{ minWidth: 100 }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Create>
  );
};
